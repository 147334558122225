import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import { Search, Trash2 } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

const defaultIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const STORAGE_KEY = 'routemyroute-locations';

const RouteMyRoute = () => {
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optimizedRoute, setOptimizedRoute] = useState([]);
  const [totalDistance, setTotalDistance] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);
  const [mapCenter, setMapCenter] = useState([20, 0]);
  const [mapZoom, setMapZoom] = useState(2);
  const mapRef = useRef(null);
  const [searchParams] = useSearchParams();

  // Load locations from localStorage on initial render
  useEffect(() => {
    const savedLocations = localStorage.getItem(STORAGE_KEY);
    if (savedLocations) {
      setLocations(JSON.parse(savedLocations));
    }
  }, []);

  // Handle URL parameters
  useEffect(() => {
    const name = searchParams.get('name');
    const lat = searchParams.get('lat');
    const lon = searchParams.get('lon');

    if (name && lat && lon) {
      const newLocation = {
        name,
        lat: parseFloat(lat),
        lon: parseFloat(lon)
      };

      // Check if location already exists
      const locationExists = locations.some(
        loc => loc.lat === newLocation.lat && 
              loc.lon === newLocation.lon && 
              loc.name === newLocation.name
      );

      if (!locationExists) {
        setLocations(prev => {
          const updatedLocations = [...prev, newLocation];
          localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedLocations));
          return updatedLocations;
        });

        // Center map on the new location
        if (mapRef.current) {
          mapRef.current.flyTo([newLocation.lat, newLocation.lon], 12);
        }
      }

      // Clear the URL parameters after processing
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [searchParams]);

  useEffect(() => {
    L.Marker.prototype.options.icon = defaultIcon;
  }, []);

  // Handle clicks outside of the suggestions dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Debounced search function
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (searchTerm.length < 3) {
        setSuggestions([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchTerm)}&limit=5`
        );
        setSuggestions(response.data);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsLoading(false);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleSelectLocation = (location) => {
    const newLocation = {
      lat: parseFloat(location.lat),
      lon: parseFloat(location.lon),
      name: location.display_name
    };
    
    setLocations(prev => {
      const updatedLocations = [...prev, newLocation];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedLocations));
      return updatedLocations;
    });

    setSearchTerm('');
    setSuggestions([]);
    setShowSuggestions(false);

    // Center map on the new location
    if (mapRef.current) {
      const map = mapRef.current;
      map.flyTo([newLocation.lat, newLocation.lon], 
                locations.length === 0 ? 12 : map.getZoom());
    }
  };

  const removeLocation = (index) => {
    setLocations(prev => {
      const updatedLocations = prev.filter((_, i) => i !== index);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedLocations));
      return updatedLocations;
    });
    setOptimizedRoute([]);
    setTotalDistance(null);
  };

  const clearAllLocations = () => {
    setLocations([]);
    localStorage.removeItem(STORAGE_KEY);
    setOptimizedRoute([]);
    setTotalDistance(null);
    if (mapRef.current) {
      mapRef.current.setView([20, 0], 2);
    }
  };

  const optimizeRoute = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://route3.albertaog.ca/optimize', {
        locations,
        osrm_server: 'http://localhost:5001'
      });
      setOptimizedRoute(response.data.optimized_route);
      setTotalDistance(response.data.total_distance);

      // Fit map bounds to show all locations
      if (mapRef.current && locations.length > 0) {
        const bounds = L.latLngBounds(locations.map(loc => [loc.lat, loc.lon]));
        mapRef.current.fitBounds(bounds, { padding: [50, 50] });
      }
    } catch (error) {
      console.error('Optimization error:', error);
      alert('Error optimizing route. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Generate a shareable URL for a location
  const getShareableUrl = (location) => {
    const baseUrl = window.location.origin + window.location.pathname;
    const params = new URLSearchParams({
      name: location.name,
      lat: location.lat.toString(),
      lon: location.lon.toString()
    });
    return `${baseUrl}?${params.toString()}`;
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-white shadow-lg p-4 z-20 relative">
        <div className="max-w-4xl mx-auto">
          {/* Search Box and Controls */}
          <div className="flex items-center gap-2 mb-4">
            <div className="relative flex-1" ref={searchRef}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a location..."
                className="w-full p-2 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {isLoading ? (
                <div className="absolute right-3 top-3 animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"/>
              ) : (
                <Search className="absolute right-3 top-3 h-4 w-4 text-gray-400" />
              )}

              {/* Suggestions Dropdown */}
              {showSuggestions && suggestions.length > 0 && (
                <div className="absolute mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-50 max-h-60 overflow-y-auto">
                  {suggestions.map((suggestion, index) => (
                    <button
                      key={index}
                      onClick={() => handleSelectLocation(suggestion)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 border-b last:border-b-0"
                    >
                      <div className="font-medium">{suggestion.display_name.split(',')[0]}</div>
                      <div className="text-sm text-gray-600 truncate">
                        {suggestion.display_name.split(',').slice(1).join(',')}
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={optimizeRoute}
              disabled={locations.length < 2 || isLoading}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
            >
              Optimize Route
            </button>
            {locations.length > 0 && (
              <button
                onClick={clearAllLocations}
                className="px-3 py-2 text-red-500 hover:text-red-600 transition-colors"
                title="Clear all locations"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            )}
          </div>

          {/* Selected Locations */}
          {locations.length > 0 && (
            <div className="mt-3 flex flex-wrap gap-2">
              {locations.map((loc, index) => (
                <div
                  key={index}
                  className="group relative"
                >
                  <div className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full text-sm hover:bg-gray-200 transition-colors">
                    <span className="font-medium">{index + 1}.</span>
                    <span className="truncate max-w-xs">{loc.name.split(',')[0]}</span>
                    <button
                      onClick={() => removeLocation(index)}
                      className="text-gray-500 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                  </div>
                  <div className="absolute left-0 right-0 -bottom-8 opacity-0 group-hover:opacity-100 transition-opacity z-50">
                    <div className="bg-black text-white text-xs rounded p-1">
                      <a
                        href={getShareableUrl(loc)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(getShareableUrl(loc));
                          alert('Shareable URL copied to clipboard!');
                        }}
                        className="hover:underline cursor-pointer"
                      >
                        Copy shareable URL
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Distance Display */}
          {totalDistance !== null && (
            <div className="mt-2 text-sm text-gray-600">
              Total distance: {(totalDistance / 1000).toFixed(2)} km
            </div>
          )}
        </div>
      </div>

      {/* Map */}
      <div className="flex-1 relative">
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          className="absolute inset-0 z-10"
          ref={mapRef}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {locations.map((loc, index) => (
            <Marker
              key={index}
              position={[loc.lat, loc.lon]}
            >
              <Popup>
                <div className="text-sm">
                  <div className="font-semibold">{index + 1}. {loc.name}</div>
                  <button
                    onClick={() => removeLocation(index)}
                    className="mt-2 text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(getShareableUrl(loc));
                      alert('Shareable URL copied to clipboard!');
                    }}
                    className="mt-2 ml-2 text-blue-500 hover:text-blue-700"
                  >
                    Copy URL
                  </button>
                </div>
              </Popup>
            </Marker>
          ))}
          {optimizedRoute.length > 0 && (
            <Polyline
              positions={optimizedRoute.map(i => [locations[i].lat, locations[i].lon])}
              color="#2563eb"
              weight={3}
              opacity={0.7}
            />
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default RouteMyRoute;